"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _Calendar = _interopRequireDefault(require("./Calendar"));
var _CalendarDay = _interopRequireDefault(require("./CalendarDay"));
var _CalendarDays = _interopRequireDefault(require("./CalendarDays"));
var _CalendarHeader = _interopRequireDefault(require("./CalendarHeader"));
var _CalendarLeft = _interopRequireDefault(require("./CalendarLeft"));
var _CalendarRight = _interopRequireDefault(require("./CalendarRight"));
var _CalendarTitle = _interopRequireDefault(require("./CalendarTitle"));
var _CalendarWeekday = _interopRequireDefault(require("./CalendarWeekday"));
var _CalendarWeekdays = _interopRequireDefault(require("./CalendarWeekdays"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
_Calendar["default"].Day = _CalendarDay["default"];
_Calendar["default"].Days = _CalendarDays["default"];
_Calendar["default"].Header = _CalendarHeader["default"];
_Calendar["default"].Left = _CalendarLeft["default"];
_Calendar["default"].Right = _CalendarRight["default"];
_Calendar["default"].Title = _CalendarTitle["default"];
_Calendar["default"].Weekdays = _CalendarWeekdays["default"];
_Calendar["default"].Weekday = _CalendarWeekday["default"];
var _default = _Calendar["default"];
exports["default"] = _default;