"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _native = _interopRequireDefault(require("styled-components/native"));
var _Div = _interopRequireDefault(require("../Div"));
var _responsive = require("../utilities/responsive");
var _templateObject;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
var CalendarWeekday = (0, _native["default"])(_Div["default"])(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  flex: 1;\n  text-align: center;\n  font-size: ", "px;\n  color: #757575;\n"])), (0, _responsive.remToDp)(1));
var _default = CalendarWeekday;
exports["default"] = _default;