"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _native = _interopRequireDefault(require("styled-components/native"));
var _responsive = require("../utilities/responsive");
var _Text = _interopRequireDefault(require("../Text"));
var _templateObject;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
var CardSubtitle = (0, _native["default"])(_Text["default"])(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  color: #212529bf;\n  margin-bottom: ", "px;\n"])), (0, _responsive.remToDp)(0.5));
var _default = CardSubtitle;
exports["default"] = _default;