"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.pxToDp = pxToDp;
exports.remToDp = remToDp;
exports.useScreenDimensions = useScreenDimensions;
var _react = require("react");
var _reactNative = require("react-native");
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i["return"] && (_r = _i["return"](), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
function useScreenDimensions() {
  var _useState = (0, _react.useState)(_reactNative.Dimensions.get("window")),
    _useState2 = _slicedToArray(_useState, 2),
    dimensions = _useState2[0],
    setDimensions = _useState2[1];
  (0, _react.useEffect)(function () {
    function handleOrientationChange(event) {
      setDimensions(event.window);
    }
    var dimensionsListener = _reactNative.Dimensions.addEventListener("change", handleOrientationChange);
    return function () {
      dimensionsListener.remove();
    };
  }, []);
  function vwToDp(vwValue) {
    var dpValue = vwValue * dimensions.width / 100;
    return _reactNative.PixelRatio.roundToNearestPixel(dpValue);
  }
  function vhToDp(vhValue) {
    var dpValue = vhValue * dimensions.height / 100;
    return _reactNative.PixelRatio.roundToNearestPixel(dpValue);
  }
  return {
    dimensions: dimensions,
    vwToDp: vwToDp,
    vhToDp: vhToDp
  };
}
function pxToDp(pxValue) {
  return _reactNative.PixelRatio.roundToNearestPixel(pxValue / _reactNative.PixelRatio.get());
}
function remToDp(remValue) {
  var baseFontSize = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 16;
  var dpValue = remValue * _reactNative.PixelRatio.getFontScale() * baseFontSize;
  return _reactNative.PixelRatio.roundToNearestPixel(dpValue);
}