"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _Form = _interopRequireDefault(require("./Form"));
var _FormButton = _interopRequireDefault(require("./FormButton"));
var _FormGroup = _interopRequireDefault(require("./FormGroup"));
var _FormInput = _interopRequireDefault(require("./FormInput"));
var _FormLabel = _interopRequireDefault(require("./FormLabel"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
_Form["default"].Button = _FormButton["default"];
_Form["default"].Group = _FormGroup["default"];
_Form["default"].Input = _FormInput["default"];
_Form["default"].Label = _FormLabel["default"];
var _default = _Form["default"];
exports["default"] = _default;